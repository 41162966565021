@import "./colors.scss";

.app {
  &__header {
    border-bottom: 3rem solid transparent;
    border-image: url("../public/media/border2.svg") 20 stretch;
    text-align: center;

    @media (max-width: 600px) {
      border-bottom: 1rem solid transparent;
      border-image: url("../public/media/border2.svg") 20 repeat;
    }

    h1 {
      font-family: "Patrick Hand";
      font-size: 400%;
      margin: 1rem 0;

      @media (max-width: 600px) {
        font-size: 100%;
      }
    }

    nav {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      a {
        font-family: "Patrick Hand", cursive;
        font-size: 150%;
        padding: 0 2rem;
        outline: none;
        text-decoration: none;
        border-radius: 1rem;

        @media (max-width: 600px) {
          font-size: 120%;
          padding: 0;
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "about" "projects" "talks" "contact";
    padding: 1% 10%;

    @media (max-width: 600px) {
      padding: 3%;
    }

    h2 {
      font-family: "Raleway", sans-serif;
      text-transform: uppercase;
    }

    h3 {
      font-family: "Inter Tight", "Helvetica Neue", "Helvetica", sans-serif;
      font-size: 120%;
      margin: 0;
    }

    section {
      margin: 0.5rem 0;
    }
  }

  .card {
    background-color: var(--background-card-ghost-white);
    border: 1px solid var(--background-card-dark-ghost-white);
    border-radius: 5% 12% 2% 8%;
    padding: 1rem;
    margin-top: 1rem;

    &__images {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      align-items: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
  }

  .about {
    grid-area: about;
    display: grid;
    grid-template-areas: "text image";
    align-items: center;
    justify-items: center;
    gap: 1rem;

    @media (max-width: 600px) {
      grid-template-areas: "text" "image";
      grid-template-columns: 1fr;
    }
  }

  .projects {
    grid-area: projects;
  }

  .talks {
    grid-area: talks;
  }

  .contact {
    grid-area: contact;
  }

  .clip-circle {
    grid-area: image;
    clip-path: circle(70px at center);
    width: 150px;
  }

  .title {
    margin: 0.5rem 0;
  }

  .text {
    grid-area: text;
  }

  a {
    font-family: "Patrick Hand";
    padding: 0 0.2rem;
    outline: none;
    text-decoration: none;
    border-radius: 1rem;
  }

  a:link {
    color: var(--text-fuchsia);
  }

  a:visited {
    color: var(--text-dark-fuchsia);
  }

  a:focus,
  a:hover {
    background: black;
    color: white;
  }

  a:active {
    border: 1px solid;
    background: var(--text-fuchsia);
  }
}
